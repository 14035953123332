import {fileDownload, getError} from "@/utils/helpers";
import ConversationService from "@/services/ConversationService";

export const namespaced = true;


export const state = () => ({
    records: [],
    record: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    pagination: {
        total: 0
    },
    recordings: [],
    usage: null,
    data: [],
    log:[],
    actions: [],
    transcript: null,
    quote: null,
    uuid: null,

    browseRecordStatus: 0,
    recordsExportStatus: 0,
    readRecordStatus: 0,
    getRecordingsStatus: 0,
    getUsageStatus: 0,
    getDataStatus: 0,
    getLogStatus: 0,
    getActionsStatus: 0,
    getTranscriptStatus: 0,
    getQuoteStatus: 0
    });

export const mutations = {
    SET_RECORDS(state, records) {
        state.records = records;
    },
    SET_RECORD(state, record) {
        state.record = record;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_UUID(state, { uuid }) {
        state.uuid = uuid;
    },     
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_BROWSE_RECORD_STATUS(state, status) {
        state.browseRecordStatus = status;
    },
    SET_RECORDS_EXPORT_STATUS(state, status){
        state.recordsExportStatus = status;
    },
    SET_READ_RECORD_STATUS(state, status) {
        state.readRecordStatus = status;
    },
    SET_PAGINATION(state, pagination){
        state.pagination = pagination;
    },
    SET_RECORDINGS(state, recordings){
        state.recordings = recordings;
    },
    SET_USAGE(state, usage) {
        state.usage = usage;
    },   
    SET_DATA(state, data){
        state.data = data;
    },
    SET_LOG(state, log){
        state.log = log;
    },
    SET_ACTIONS(state, actions){
        state.actions = actions;
    },
    SET_TRANSCRIPT(state, transcript){
        state.transcript = transcript;
    },
    SET_QUOTE(state, { quote }) {
        state.quote = quote;
    },
    SET_GET_RECORDINGS_STATUS(state, status){
        state.getRecordingsStatus = status;
    },
    SET_GET_USAGE_STATUS(state, status){
        state.getUsageStatus = status;
    },
    SET_GET_DATA_STATUS(state, status){
        state.getDataStatus = status;
    },
    SET_GET_LOG_STATUS(state, status){
        state.getLogStatus = status;
    },
    SET_GET_ACTIONS_STATUS(state, status){
        state.getActionsStatus = status;
    },
    SET_GET_TRANSCRIPT_STATUS(state, status){
        state.getTranscriptStatus = status;
    },
    SET_GET_QUOTE_STATUS(state, status){
        state.getQuoteStatus = status;
    },
};

export const getters = {
    records: state => state.records,
    record: state => state.record,
    meta: state => state.meta,
    links: state => state.links,
    loading: state => state.loading,
    error: state => state.error,
    browseRecordStatus: state => state.browseRecordStatus,
    recordsExportStatus: state => state.recordsExportStatus,
    readRecordStatus: state => state.readRecordStatus,
    pagination: state => state.pagination,
    paginateRecordStatus: state => state.paginateRecordStatus,
    recordings: state => state.recordings,
    usage: state => state.usage,
    data: state => state.data,
    log: state => state.log,
    actions: state => state.actions,
    transcript: state => state.transcript,
    quote: state => state.quote,
    uuid: state => state.uuid,
    getRecordingsStatus: state => state.getRecordingsStatus,
    getUsageStatus: state => state.getUsageStatus,
    getDataStatus: state => state.getDataStatus,
    getLogStatus: state => state.getLogStatus,
    getActionsStatus: state => state.getActionsStatus,
    getTranscriptStatus: state => state.getTranscriptStatus,
    getQuoteStatus: state => state.getQuoteStatus
};

export const actions = {
    browseRecords({commit}, filters) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
        commit("SET_BROWSE_RECORD_STATUS", 1);
        ConversationService.getConversations(filters).then((response) => {
            commit("SET_RECORDS", response.data.data);
            delete response.data.meta.links;
            commit("SET_PAGINATION", response.data.meta);
            commit("SET_BROWSE_RECORD_STATUS", 2);
        }).catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_BROWSE_RECORD_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },

    browseRecordsExport({commit}, filters) {
        commit("SET_ERROR", null);
        commit("SET_RECORDS_EXPORT_STATUS", 1);
        ConversationService.getConversations(filters).then((response) => {
            let contentDispositionHeader = response.headers['content-disposition'];
            let filename = contentDispositionHeader.split(';')[1].split('=')[1]?.replace('"', '')?.replace('"', '');
            fileDownload(response.data, filename);
            commit("SET_RECORDS_EXPORT_STATUS", 2);
        }).catch((error) => {
            commit("SET_RECORDS_EXPORT_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },

    readRecord({commit}, recordId) {
        commit("SET_ERROR", null);
        commit("SET_READ_RECORD_STATUS", 1);
        ConversationService.getConversation(recordId).then((response) => {
            commit("SET_READ_RECORD_STATUS", 2);
            commit("SET_RECORD", response.data.data);
        }).catch((error) => {
            commit("SET_READ_RECORD_STATUS", 3);
            commit("SET_RECORD", null);
            commit("SET_ERROR", getError(error));
        });
    },

    getConversationRecordings({commit}, recordId) {
        commit("SET_GET_RECORDINGS_STATUS", 1);
        commit("SET_RECORDINGS", null);
        commit("SET_ERROR", null);
        ConversationService.getConversationRecordings(recordId).then((response) => {
            commit("SET_GET_RECORDINGS_STATUS", 2);
            commit("SET_RECORDINGS", response.data.data);
        }).catch((error) => {
            commit("SET_GET_RECORDINGS_STATUS", 3);
            commit("SET_RECORDINGS", null);
            commit("SET_ERROR", getError(error));
        });
    },

    getConversationData({commit}, recordId) {
        commit("SET_GET_DATA_STATUS", 1);
        commit("SET_DATA", null);
        commit("SET_ERROR", null);
        ConversationService.getConversationData(recordId).then((response) => {
            commit("SET_GET_DATA_STATUS", 2);
            commit("SET_DATA", response.data.data);
        }).catch((error) => {
            commit("SET_GET_DATA_STATUS", 3);
            commit("SET_DATA", null);
            commit("SET_ERROR", getError(error));
        });
    },

    getConversationLog({commit}, recordId) {
        commit("SET_GET_LOG_STATUS", 1);
        commit("SET_LOG", null);
        commit("SET_ERROR", null);
        ConversationService.getConversationLog(recordId).then((response) => {
            commit("SET_GET_LOG_STATUS", 2);
            commit("SET_LOG", response.data.data);
        }).catch((error) => {
            commit("SET_GET_LOG_STATUS", 3);
            commit("SET_LOG", null);
            commit("SET_ERROR", getError(error));
        });
    },

    getConversationActions({commit}, recordId) {
        commit("SET_GET_ACTIONS_STATUS", 1);
        commit("SET_ACTIONS", null);
        commit("SET_ERROR", null);
        ConversationService.getConversationActions(recordId).then((response) => {
            commit("SET_GET_ACTIONS_STATUS", 2);
            commit("SET_ACTIONS", response.data.data);
        }).catch((error) => {
            commit("SET_GET_ACTIONS_STATUS", 3);
            commit("SET_ACTIONS", null);
            commit("SET_ERROR", getError(error));
        });
    },

    getConversationTranscript({commit}, recordId) {
        commit("SET_GET_TRANSCRIPT_STATUS", 1);
        commit("SET_TRANSCRIPT", null);
        commit("SET_ERROR", null);
        let filters = {
            id: recordId,
            export: false
        }
        ConversationService.getConversationTranscript(filters).then((response) => {
            commit("SET_GET_TRANSCRIPT_STATUS", 2);
            commit("SET_TRANSCRIPT", response.data);
        }).catch((error) => {
            commit("SET_GET_TRANSCRIPT_STATUS", 3);
            commit("SET_TRANSCRIPT", null);
            commit("SET_ERROR", getError(error));
        });
    },

    callTranscriptExport({commit}, recordId) {
        commit("SET_GET_TRANSCRIPT_STATUS", 1);
        commit("SET_ERROR", null);
        let filters = {
            id: recordId,
            export: true
        }
        ConversationService.getConversationTranscript(filters).then((response) => {
            let contentDispositionHeader = response.headers['content-disposition'];
            let filename = contentDispositionHeader.split(';')[1].split('=')[1]?.replace('"', '')?.replace('"', '');
            fileDownload(response.data, filename);
            commit("SET_GET_TRANSCRIPT_STATUS", 2);
        }).catch((error) => {
            commit("SET_GET_TRANSCRIPT_STATUS", 3);
            commit("SET_ERROR", getError(error));
        });
    },

    getQuote({ commit }, record) {
        commit("SET_UUID", 0);
        commit("SET_GET_QUOTE_STATUS", 1);
        let convId = record?.conversationId;
        return ConversationService.getQuote(convId)
            .then(response => {
                console.log("Response data:", response.data); 
                if (response.data && response.data?.length > 0) {
                    let uuid = response.data[0].uuid;
                    commit("SET_QUOTE", response.data);
                    commit("SET_GET_QUOTE_STATUS", 2);
                    commit("SET_UUID", { uuid });
                } 
            })
            .catch(error => {
                commit("SET_GET_QUOTE_STATUS", 3);
                commit("SET_QUOTE", null);
                commit("SET_ERROR", getError(error));
            });
    },   
    getUsage({commit}, recordId) {
        commit("SET_GET_USAGE_STATUS", 1);
        commit("SET_USAGE", null);
        commit("SET_ERROR", null);
        ConversationService.getUsage(recordId).then((response) => {
            commit("SET_GET_USAGE_STATUS", 2);
            commit("SET_USAGE", response.data.data);
        }).catch((error) => {
            commit("SET_GET_USAGE_STATUS", 3);
            commit("SET_USAGE", null);
            commit("SET_ERROR", getError(error));
        });
    },     
};