import ModelView from "../../views/ModelView";
import { AUTH_HELPER } from "../../utils/helpers";

export default [
    {
        path: '/reporting',
        name: 'Reporting',
        redirect: '/reporting/',
        meta: {
            requirePermission: true,
            model: AUTH_HELPER.authModels.Reporting
        },
        component: ModelView,
        children: [
            {
                path: '',
                name: 'Reporting',
                meta: {
                    action: AUTH_HELPER.authActions.browse,
                    breadcrumb: [
                        {
                            label: 'Reporting',
                        }
                    ]
                },
                component: () => import('../../views/Reporting/Index')
            },
            {
                path: '/reporting/conversations',
                name: 'Conversations',
                redirect: '/reporting/conversations/',
                component: ModelView,
                children: [
                    {
                        path: '',
                        name: 'Conversations',
                        meta: {
                            action: AUTH_HELPER.authActions.browse,
                            breadcrumb: [
                                {
                                    label: 'Reporting/Conversations'
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Conversations/Index'),
                    },
                    {
                        path: "/reporting/conversations/:ConversationId/:tab?",
                        name: "Conversation",
                        meta: {
                            action: AUTH_HELPER.authActions.add,
                            breadcrumb: [
                                {
                                    parent: 'Reporting/Conversations'
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Conversations/Conversation'),
                        children: [
                            {
                                path: '/reporting/conversations/:ConversationId/:tab?',
                                name: 'Conversation',
                                component: () => import('../../views/Reporting/Conversations/Conversation'),
                            },
                            // {
                            //     path: '/reporting/conversations/:ConversationId/:tab',
                            //     name: 'Conversation',
                            //     component: () => import('../../views/Reporting/Conversations/Conversation'),
                            // }                            
                        ]
                    }                    
                ]
            },
            {
                path: '/reporting/roles',
                name: 'Roles',
                redirect: '/reporting/roles/',
                component: ModelView,
                children: [
                    {
                        path: '',
                        name: 'Roles',
                        meta: {
                            action: AUTH_HELPER.authActions.browse,
                            breadcrumb: [
                                {
                                    label: 'Reporting/Roles'
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Roles/Index'),
                    },
                    {
                        path: "/reporting/roles/breakdown",
                        name: "Role",
                        meta: {
                            action: AUTH_HELPER.authActions.add,
                            breadcrumb: [
                                {
                                    parent: 'Reporting/Roles'
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Roles/Role'),
                    }
                ]
            },
            {
                path: '/reporting/instances',
                name: 'Instances',
                redirect: '/reporting/instances/',
                component: ModelView,
                children: [
                    {
                        path: '',
                        name: 'Instances',
                        meta: {
                            action: AUTH_HELPER.authActions.browse,
                            breadcrumb: [
                                {
                                    label: 'Reporting/Instances'
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Instances/Index'),
                    },
                    {
                        path: "/reporting/instances/breakdown",
                        name: "Instance Breakdown",
                        meta: {
                            action: AUTH_HELPER.authActions.add,
                            breadcrumb: [
                                {
                                    parent: 'Reporting/Instances'
                                }
                            ]
                        },
                        component: () => import('../../views/Reporting/Instances/Instance'),
                    }
                ]
            }
        ]
    },
];